import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/repository/src/layouts/PageLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Measures to support accessibility`}</h2>
    <ul>
      <li parentName="ul">{`Include accessibility as part of our mission statement.`}</li>
      <li parentName="ul">{`Include accessibility throughout our internal policies.`}</li>
      <li parentName="ul">{`Provide continual accessibility training for our staff.`}</li>
      <li parentName="ul">{`Assign clear accessibility targets and responsibilities.`}</li>
      <li parentName="ul">{`Employ formal accessibility quality assurance methods.`}</li>
    </ul>
    <h2>{`Conformance status`}</h2>
    <p>{`The `}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/standards-guidelines/wcag/"
      }}>{`Web Content Accessibility Guidelines (WCAG)`}</a>{`
defines requirements for designers and developers to improve accessibility for people with
disabilities. It defines three levels of conformance: Level A, Level AA, and Level AAA. Jacobs
Enforcement is not assessed with WCAG 2.1 level AA. Not assessed means that the content has not been
evaluated or the evaluation results are not available.`}</p>
    <h2>{`Feedback`}</h2>
    <p>{`We welcome your feedback on the accessibility of Jacobs Enforcement. Please let us know if you
encounter accessibility barriers on Jacobs Enforcement:`}</p>
    <p>{`We try to respond to feedback within 2 business days.`}</p>
    <h2>{`Technical specifications`}</h2>
    <ul>
      <li parentName="ul">{`HTML`}</li>
      <li parentName="ul">{`CSS`}</li>
      <li parentName="ul">{`Javascript`}</li>
    </ul>
    <p>{`These technologies are relied upon for conformance with the
accessibility standards used.`}</p>
    <h2>{`Assessment approach`}</h2>
    <p>{`Jacobs Enforcement assessed the accessibility of Jacobs Enforcement by
the following approaches:`}</p>
    <ul>
      <li parentName="ul">{`Self-evaluation`}</li>
    </ul>
    <hr></hr>
    <h2>{`How to Increase the Font Size on Our Website`}</h2>
    <p><strong parentName="p">{`If you're using Google Chrome`}</strong></p>
    <ul>
      <li parentName="ul">{`Hold CTRL on your Keyboard (CMD if you're using an Apple product)`}</li>
      <li parentName="ul">{`While holding the CTRL / CMD key, press the '+' to increase the size of the text`}</li>
      <li parentName="ul">{`To decrease the font size, hold the CTRL / CMD key and press the '-' key`}</li>
    </ul>
    <p><strong parentName="p">{`If you're using Internet Explorer`}</strong></p>
    <ul>
      <li parentName="ul">{`Select 'View' from the browser menu`}</li>
      <li parentName="ul">{`Select 'Text Size'`}</li>
      <li parentName="ul">{`Select the 'Largest' option`}</li>
    </ul>
    <hr></hr>
    <h2>{`Here's a few things we do to help`}</h2>
    <ul>
      <li parentName="ul">{`Our site is designed for screen reader compatibility`}</li>
      <li parentName="ul">{`We use a strong colour contrasts that comply with the Web Content Accessibility Guidelines (WCAG)`}</li>
      <li parentName="ul">{`Links are clearly explained. For example, we don't use 'click here'.`}</li>
      <li parentName="ul">{`Style sheets are used to determine the sites presentation, this helps with assistive technologies.`}</li>
    </ul>
    <p>{`If you have any problems with out site, let us know via our `}<a parentName="p" {...{
        "href": "/contact"
      }}>{`Contact`}</a>{` page and we'll do
what we can to help.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      